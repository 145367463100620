/*
* Template Name: Sunshine - Responsive vCard Template
* Author: LMPixels
* Author URL: http://themeforest.net/user/LMPixels
* Version: 2.1
*/

/* =============================================================================

1. Fonts
2. General styles (Buttons, Inputs, Typography)
3. Page loadig animation
4. Subpages changer
5. Site header (sidebar)
6. Site content
7. Subpages
  7.1. About Me subpage
  7.2. Resume subpage
  7.3. Work subpage
  7.4. Blog subpage
  7.5. Contact subpage
8. Sliders (testimonials & portfolio carousels)
9. Work Full info page
10. Blog post page
11. Media Queries
12. Updates

============================================================================= */


/* =============================================================================
1. Fonts
============================================================================= */
/* 7 stroke icons font by Pixeden */ /* Full icons list: http://themes-pixeden.com/font-demos/7-stroke/index.html */

/* Font awesome icons font */ /* Full icons list: http://fontawesome.io/icons/ */

/* Google fonts */
/*@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext,cyrillic");
*/

/* =============================================================================
2. General styles
============================================================================= */

html {
  height: 100%;
}
body {
  font-family: 'PT Sans', Helvetica, sans-serif;
  color: #666;
  margin: 0;
  height: 100%;
  font-size: 100%;
  line-height: 1.5em
}

a {
  color: #0099CC;
  text-decoration: none;
  outline: none;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

a:hover {
  color: #006699;
  color: #0099CC;
  text-decoration: none;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #222;
  font-family: Oswald, Helvetica, sans-serif;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  font-size: 0.875em;
  margin: 0 0 10px;
  font-weight: 300;
}

p.lead {
  font-size: 18px;
  margin: 30px 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

.page {
  position: relative;
  height: 100%;
}

.mobile-visible {
  display: none;
  visibility: hidden;
}

/* Buttons */
input[type=submit], input[type=button], button, a.button {
  display: inline-block;
  position: relative;
  padding: 0.8em 2em;
  margin-bottom: .25em;
  font-size: 0.875em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #34c7a9;
  color: #222;
  text-shadow: none;
  background: none;
  border-radius: 0;
  font-family: 'PT Sans', Helvetica, sans-serif;
}
.btn {
  border-radius: 0;
}

input[type=submit]:hover, input[type=submit]:focus, input[type=button]:focus, button:hover, button:focus, a.button:hover, a.button:focus {
  color: #fff;
  background-color: #34c7a9;
}
/* /Buttons */

/* Contact form */
.form-group {
  position: relative;
  margin: 0 0 20px;
}
.form-control,
.form-control:focus {
  height: 42px;
}

.form-control,
.form-control:focus,
.has-error .form-control,
.has-error .form-control:focus {
  position: relative;
  border: 1px solid #dfdfdf;
  border-radius: 0;
  display: block;
  font-size: 0.875em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: 'PT Sans', Helvetica, sans-serif;
}

textarea.form-control, textarea.form-control:focus {
  height: auto;
}

.form-control + .form-control-border {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: #34c7a9;
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.form-control:focus + .form-control-border {
  left: 0;
  right: 0;
  z-index: 1;
}

.has-error .form-control + .form-control-border {
  background: #ff4e4e;
}

.form-control-icon {
  position: absolute;
  top: 13px;
  right: 13px;
  color: #d3d3d3;
}

.form-control:focus + .form-control-icon {
  color: #34c7a9;
}

/* Placeholders */
.form-control::-moz-placeholder {
  color: #adadac;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #adadac;
}

.form-control::-webkit-input-placeholder {
  color: #adadac;
}
/* /Placeholders */

.form-group .help-block {
  position: absolute;
  display: inline-block;
  padding: 0px 5px;
  font-size: 0.8em;
  line-height: 1.5em;
  margin: -2px 0 0 10px;
  color: #fff;
  background: #ff4e4e;
}

.form-group .help-block:after {
  content: " ";
  position: absolute;
  left: 5px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ff4e4e;
  border-right: 10px solid transparent;
}
.form-group .help-block:empty {
  display: none;
}
/* /Contact form */

.no-padding {
  padding: 0;
}

.subpage-block {
  margin-bottom: 30px;
}

/* =============================================================================
3. Page loadig animation
============================================================================= */
.no-js .preloader { display: none;  }
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
}

.preloader-animation {
  margin: -20px auto 0;
  top: 50%;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  text-align: center;
  -webkit-animation: rotate 2.0s infinite linear;
  animation: rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #34c7a9;
  border-radius: 100%;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg)
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0.0);
  }
  50% {
    -webkit-transform: scale(1.0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


/* =============================================================================
4. Subpages changer
============================================================================= */
.pt-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: -5px;
  padding-right: 5px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.pt-wrapper.scroll-auto {
  overflow-x: auto;
  overflow-y: visible;
}

.pt-wrapper-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.pt-page {
  width: 100%;
  height: 0;
  position: absolute;
  opacity: 0;
  top: auto;
  left: 0;
  padding: 0 70px 50px;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition-duration: 0.55s;
  transition-duration: 0.55s;
}

.no-js .pt-page {
  position: relative;
}

.pt-page * {
  height: 0;
  opacity: 0;
}

.pt-page .portfolio-grid,
.pt-page .blog-masonry {
  display: none;
}

.pt-page-content {
  position: absolute;
  width: 100%;
}

.pt-page-current,
.no-js .pt-page {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  height: auto;
}

.pt-page.pt-page-current .portfolio-grid,
.pt-page.pt-page-current .blog-masonry {
  display: block;
}

.pt-page-current *,
.no-js .pt-page * {
  height: auto;
  opacity: 1;
}

.no-js body {
  overflow: auto;
}

/*No Animation Support Message Style*/
.pt-message {
  display: none;
  position: absolute;
  z-index: 99999;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #da475c;
  color: #fff;
  text-align: center;
}

.pt-message p {
  margin: 0;
  line-height: 60px;
  font-size: 26px;
}

.no-cssanimations .pt-message {
  display: block;
}

.subpages {
  width: 100%;
  position: absolute;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
}
/* /Page changer */

/* =============================================================================
5. Site header (sidebar)
============================================================================= */
.header {
  position: fixed;
  width: 25%;
  height: 100%;
  overflow: hidden;
}

.my-photo {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 0;
}

.my-photo-small {
  display: none;
}

.my-photo img {
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: none;
  min-height: 550px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.my-photo .mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.08)
}

.site-title-block {
  position: relative;
  z-index: 1;
  margin: 30px 20px 10px;
  text-align: center;
}

.site-title {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.1em;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

p.site-description {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  font-family: Oswald, Helvetica, sans-serif;
  margin: 10px 0;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

/* Social links */
ul.social-links {
  display: inline-block;
  float: right;
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

ul.social-links li {
  float: left;
  margin: 0;
  padding: 0;
}

ul.social-links li a {
  display: inline-block;
  margin: 0 2px;
  width: 28px;
  height: 28px;
  color: #888;
  background-color: #eee;
  text-align: center;
}

ul.social-links li a:hover {
  color: #fff;
  background-color: #34c7a9;
}

ul.social-links li a i {
  font-size: 14px;
  line-height: 28px;
}

ul.social-links li a:hover i {
  color: #fff;
}

ul.social-links li a:hover {
  color: #34c7a9;
}

ul.social-links li:last-child a {
  margin-right: 0;
}
/* /Social links */

.menu-toggle {
  display: block;
  width: 50px;
  margin: 0 4px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
  float: right;
}

.menu-toggle i {
  color: #222;
  font-size: 21px;
  line-height: 50px;
}

/* ----- Header ----- */


/* =============================================================================
6. Site content
============================================================================= */
.site-main {
  height: 100%;
  margin-left: 25%;
  position: relative;
  background-color: #fff;
  border-left: 5px solid #eee;
  -webkit-box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.04);
  -moz-box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.04);
  box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.04);
}

/* Main menu */
.site-nav {
  display: block;
  padding: 30px 70px;
}
.site-main-menu {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: Oswald, Helvetica, sans-serif;
}

.site-main-menu li {
  display: inline-block;
  position: relative;
  padding: 10px 15px;
}

.site-main-menu li:first-child {
  padding-left: 0;
}

.site-main-menu li a,
.site-main-menu li a:hover {
  color: #222;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  outline: none;
}

.site-main-menu li a:hover:after,
.site-main-menu li.active a:after {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  content: '';
  width: 21px;
  height: 2px;
  margin: 0 auto;
  background-color: #eee;
}

.site-main-menu li.active a:after {
  background-color: #34c7a9;
}
/* /Main menu */

/* Section title */
.section-title-block {
  margin-bottom: 40px;
}

.section-title {
  display: inline-block;
  position: relative;
  margin: 0 0 0 -65px;
  padding: 10px 20px 10px 63px;
  background-color: #34c7a9;
  color: #fff;
  line-height: 1.1em;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.section-title:after {
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  bottom: 5px;
  left: -5px;
  content: "";
  border: 1px solid #2dad94;
  transform: skew(0deg,65deg);
  -ms-transform: skew(0deg,65deg);
  -webkit-transform: skew(0deg,65deg);
  -o-transform: skew(0deg,65deg);
  -moz-transform: skew(0deg,65deg);
  background: #2dad94;
}

.section-description {
  display: inline-block;
  margin:  0 0 0 15px;
  color: #888;
  font-size: 14px;
  font-weight: 200;
}

.block-title h3 {
  display: inline-block;
  margin: 0 0 15px;
  padding-bottom: 3px;
}

.block-title h3:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #34c7a9;
}


/* =============================================================================
7. Subpages
============================================================================= */

/* =======================================
7.1. About Me subpage
======================================= */
/* About me General info */
.general-info {

}
/* /About me General info */

/* About me list */
.info-list {
  list-style: none;
  margin: 0;
  padding: 15px 0 0px;
}

.info-list li {
  padding: 3px 0;
  font-size: 14px;
}

.info-list li .title {
  display: inline-block;
  font-family: Oswald, Helvetica, sans-serif;
  margin-right: 15px;
  color: #222;
}

.info-list li .title:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #34c7a9;
}

.info-list li .value {
  font-family: 'PT Sans';
  min-width: 250px;
}
/* /About me list */

/* Services */
.service-block {
  text-align: center;
}

.service-block img {
  max-width: 100px;
  max-height: 74px;
  margin: 0 0 18px;
}

.service-icon {
  font-size: 70px;
  color: #34c7a9;
  margin: 0 0 18px;
}
/* /Services */

/* Clients */
.client_block {
  text-align: center;
  padding: 5px 0;
  opacity: 0.4;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.client_block:hover {
  opacity: 1;
}

.client_block img {
  max-width: 80px;
}
/* /Clients */

/* Fun Facts */
.fun-fact-block {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0;
  background: #fff;
  padding: 20px 10px 15px;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 1px 5px rgba(0,0,0,0.1);
  box-shadow: 0 1px 5px rgba(0,0,0,0.1);
}

.fun-fact-block.gray-bg {
  background-color: #fafafa;
}

.fun-fact-block h4 {
  margin-top: 10px;
}

.fun-fact-block .pe-7s-icon {
  position: relative;
  font-size: 56px;
  color: #888;
}

.fun-fact-block .fun-value {
  font-size: 24px;
  font-weight: 300;
  display: block;
  margin: 10px 0;
  color: #34c7a9;
}
/* /Fun Facts */

/* Testimonials */
.testimonial-item {
  width: 100%;
  margin: 0 auto;
}

.testimonial-credits {
  margin-top: 10px;
  display: block;
  position: relative;
  height: 80px;
}

.testimonial-author {
  display: inline-block;
  font-size: 15px;
  font-family: Oswald, Helvetica, sans-serif;
  color: #222;
  margin: 0;
  font-weight: 500;
  text-align: center
}

.testimonial-author-info {
  display: inline-block;
  position: relative;
  padding-left: 92px;
}

.testimonial-content {
  padding-left: 50px;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.testimonial-content .testimonial-text {
  padding: 1.4em 1.8em;
  font-style: italic;
  background-color: #fcfcfc;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 2px solid #e5e5e5;
  width: 100%;
  position: relative;
  margin: 0;
  z-index: -1;
}

.testimonial-content .testimonial-text p {
  margin: 0;
}

.testimonial-picture {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0;
  top: -40px;
  border-radius: 40px;
  z-index: 1;
}

.testimonial-picture img {
  width: 100%;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 2px solid #ddd;
}

.testimonial-firm {
  font-size: 10px;
  line-height: 1.5em;
  margin: 0;
  text-transform: uppercase;
  font-style: italic;
  font-weight: lighter
}
/* /Testimonials */


/* =======================================
7.2. Resume subpage
======================================= */
/* Timeline  */
.timeline {
  position: relative;
  border-left: 1px solid #f4f9ed;
  padding-top: 6px;
  padding-bottom: 10px;
}

.timeline-event {
  position: relative;
  padding-left: 25px;
  padding-bottom: 15px;
}

.timeline-event:last-child {
  padding-bottom: 0px;
}

.timeline-event > * {
  display: block;
}

.event-date {
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 300;
  line-height: 1.1em;
  margin: 0 0 3px;
  background-color: #9bd952;
  padding: 5px 8px 5px 23px;
  margin-left: -23px;
}

.event-date:after {
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  bottom: 3px;
  left: -3px;
  content: "";
  transform: skew(0deg,65deg);
  -ms-transform: skew(0deg,65deg);
  -webkit-transform: skew(0deg,65deg);
  -o-transform: skew(0deg,65deg);
  -moz-transform: skew(0deg,65deg);
  background: #8cc44b;
}

.event-name {
  margin: 10px 0 0;
}

.event-description {
  color: #a5a5a5;
  margin: 0 0 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.45em;
}

.download-cv-block {
  margin: 0 0 20px;
}

/* Skills */
.skills-info {
  margin-bottom: 30px;
}

.skills-info h4 {
  font-size: 0.875em;
  line-height: 1.1em;
  position: relative;
  margin: 0 0 5px;
}

.skill-container {
  background-color: transparent;
  border: 2px solid #34c7a9;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 14px;
  margin-bottom: 12px;
  width: 100%;
}

.skill-percentage {
  background-color: #34c7a9;
  border: 2px solid #fff;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  height: 10px;
  padding: 0;
}
/* Animate skills on subpage load */
.pt-page .skill-percentage {
  -webkit-transition: all 2.5s ease-in-out;
  -moz-transition: all 2.5s ease-in-out;
  -o-transition: all 2.5s ease-in-out;
  -ms-transition: all 2.5s ease-in-out;
  transition: all 2.5s ease-in-out;
}
.js .pt-page:not(.pt-page-current) .skill-percentage {
  width: 0;
}

/*Skill 1*/
.skill-percentage.skill-1 {
  width: 88%;
}
/*Skill 2*/
.skill-percentage.skill-2 {
  width: 75%;
}
/*Skill 3*/
.skill-percentage.skill-3 {
  width: 60%;
}
/*Skill 4*/
.skill-percentage.skill-4 {
  width: 95%;
}
/*Skill 5*/
.skill-percentage.skill-5 {
  width: 85%;
}
/*Skill 6*/
.skill-percentage.skill-6 {
  width: 72%;
}
/*Skill 7*/
.skill-percentage.skill-7 {
  width: 60%;
}
/*Skill 8*/
.skill-percentage.skill-8 {
  width: 77%;
}
/* Skills */


/* =======================================
7.3. Work subpage
======================================= */
.page-ajax-loaded {
  /*display: none;*/
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
}

.portfolio-grid {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.portfolio-grid figure {
  width: 25%;
  float: left;
  padding: 0.5em;
}

.portfolio-grid img {
  position: relative;
  display: block;
  width: 100%;
}

.portfolio-filters {
  list-style: none;
  padding: 0;
  margin: 0 0 2em;
  text-align: center;
}
.portfolio-filters li {
  display: inline-block;
}

.portfolio-filters li a,
.portfolio-filters li a:hover {
  color: #888;
  text-decoration: none;
}

.portfolio-filters li a:hover {
  background-color: #eee;
}

.portfolio-filters li.active a,
.portfolio-filters li.active a:hover {
  background-color: #34c7a9;
  color: #fff;
}

/* Direction-Aware Hover Effect */
.portfolio-grid figure a div {
  top: 0px;
  left: -100%;
  padding: 5px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-grid figure a:hover div {
  left: 0px;
}

.portfolio-grid figure {
  float: left;
  background: #fff;
  position: relative;
}

.portfolio-grid figure a,
.portfolio-rid figure a img {
  display: block;
  position: relative;
}

.portfolio-grid figure a {
  position: relative;
  display: block;
  overflow: hidden;
}

.portfolio-grid figure a div {
  position: absolute;
  background: #4f565e;
  background: rgba(79,86,94,0.7);
  width: 100%;
  height: 100%;
  color: #888;
}
.portfolio-grid figure a div h5 {
  display: inline-block;
  font-size: 1rem;
  margin: 10px 0;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.portfolio-grid figure a div h5:after {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background-color: #34c7a9;
}
.portfolio-grid figure a div small {
  color: #eee;
  position: absolute;
  bottom: 10px;
  left: 20px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.portfolio-grid figure a div i {
  color: #eee;
  position: absolute;
  bottom: 14px;
  right: 20px;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.portfolio-grid figure a div span {
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: normal;
  color: rgba(255,255,255,0.9);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.5);
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 -10px 0 rgba(255,255,255,0.3);
  -moz-box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 -10px 0 rgba(255,255,255,0.3);
  box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 -10px 0 rgba(255,255,255,0.3);
}
/* End of Direction-Aware Hover Effect */

/* =======================================
7.4. Blog subpage
======================================= */
/* Blog */
.blog-masonry {
  margin: 0 -1em;
}
.blog-masonry .item {
  width: 50%;
  float: left;
  padding: 1em;
}

.blog-card {
  display: block;
  position: relative;
  padding: 0;
  background: #fff;
  text-align: center;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 1px 5px rgba(0,0,0,0.1);
  box-shadow: 0 1px 5px rgba(0,0,0,0.1);
}

.blog-card:hover {
  -webkit-box-shadow: 0 1px 15px rgba(0,0,0,0.11);
  -moz-box-shadow: 0 1px 15px rgba(0,0,0,0.11);
  box-shadow: 0 1px 15px rgba(0,0,0,0.11);
}

.blog-card .post-image {
  width: 100%;
  margin: 0;
}

.blog-card .post-info {
  padding: 0.8em 1.2em 1.4em;
}

.blog-card .blog-item-title {
  margin: 5px 0 0 0;
}

.blog-card .post-meta {
  padding: 1em;
  margin: 0;
}

.blog-card .post-date {
  display: inline-block;
  background-color: #34c7a9;
  padding: 0.3em;
  text-align: center;
  position: absolute;
  bottom: -10px;
  right: 1em;
  min-width: 44px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.blog-card .post-date span {
  display: block;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  margin: 0;
}

.blog-card .media-block {
  position: relative;
}

.blog-card .media-block a .mask {
  position: absolute;
  background: #4f565e;
  background: rgba(79,86,94,0.6);
  display: inline-block;
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.blog-card .media-block a .mask:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -33px 0 0 -33px;
  width: 66px;
  line-height: 66px;
  font-size: 66px;
  text-align: center;
  content: "\E62E";
  color: #fff;
}

.blog-card .media-block a:hover .mask {
  visibility: visible;
  opacity: 1;
}

.blog-card .post-date span.day {
  font-size: 18px;
  margin: 3px 0;
}

.blog-card .post-date span.month {
  font-weight: 300;
  margin-bottom: 3px;
}

.blog-card .post-date span.year {
  font-size: 0.8em;
}

.blog-card ul.category {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog-card ul.category li {
  display: inline-block;
}

.blog-card ul.category li a {
  color: #888;
  font-size: 0.8em;
  font-weight: 300;
}

.blog-card .post-meta .item, .post-meta .item a {
  color: #a6a6a6;
}

.post-meta .item {
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}

.post-meta .item:before {
  padding-right: 5px;
}


/* =======================================
7.5. Contact subpage
======================================= */
/* Contact info */
.contact-info-block {
  position: relative;
  text-align: left;
  width: 100%;
  display: block;
  margin: 0;
  padding: 10px 10px 10px 0;
}

.contact-info-block:last-child {
  margin-bottom: 30px;
}

.contact-info-block .ci-icon {
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.ci-text {
  position: relative;
  padding-left: 50px;
}

.contact-info-block h5 {
  margin: 5px 0;
}

.contact-info-block .pe-7s-icon {
  position: relative;
  font-size: 30px;
  color: #888;
}
/* /Contact info */


/* =============================================================================
8. Sliders
============================================================================= */
/* Testimonials Carousel */
.testimonials.owl-carousel .owl-nav {
  position: absolute;
  text-align: right;
  right: 0;
  bottom: 5px;
}

.testimonials.owl-carousel .owl-nav .owl-prev {
  margin-right: 3px;
}

.testimonials.owl-carousel .owl-nav .owl-prev,
.testimonials.owl-carousel .owl-nav .owl-next {
  display: inline-block;
  background-color: #eee;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.testimonials.owl-carousel .owl-nav .owl-prev:hover,
.testimonials.owl-carousel .owl-nav .owl-next:hover {
  color: #fff;
  background-color: #34c7a9;
}

.testimonials.owl-carousel .owl-nav .owl-prev:before,
.testimonials.owl-carousel .owl-nav .owl-next:before {
  position: relative;
  margin: 2px;
  width: 22px;
  height: 22px;
  font-family: FontAwesome;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  display: block;
  cursor: pointer;
}

.testimonials.owl-carousel .owl-nav .owl-prev:before {
  content: "\F104";
}

.testimonials.owl-carousel .owl-nav .owl-next:before {
  content: "\F105";
}
/* /Testimonials Carousel */

/* Work Carousel */
.portfolio-page-carousel {
  overflow: hidden;
}

.portfolio-page-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -20px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.portfolio-page-carousel .owl-nav [class*='owl-'] {
  color: #888;
  margin: 0;
  padding: 4px 7px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-page-carousel .owl-nav [class*='owl-']:before {
  display: block;
  font-family: FontAwesome;
  font-size: 30px;
  line-height: 30px;
}

.portfolio-page-carousel .owl-nav .owl-prev {
  float: left;
  margin-left: -50px;
}

.portfolio-page-carousel:hover .owl-nav .owl-prev {
  margin-left: 0;
}

.portfolio-page-carousel .owl-nav .owl-next {
  float: right;
  margin-right: -50px;
}

.portfolio-page-carousel:hover .owl-nav .owl-next {
  margin-right: 0;
}

.portfolio-page-carousel .owl-nav .owl-prev:before {
  content: "\F104";
}

.portfolio-page-carousel .owl-nav .owl-next:before {
  content: "\F105";
}

.portfolio-page-carousel .owl-dots {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 10px 5px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.portfolio-page-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.portfolio-page-carousel .owl-dots .owl-dot.active span, .portfolio-page-carousel .owl-dots .owl-dot:hover span {
  background: #888;
}

.portfolio-page-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px;
  background: #f5f5f5;
  border: 1px solid #888;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
/* Work Carousel */


/* =============================================================================
9. Work Full info page
============================================================================= */
.portfolio-page-content {
  padding-bottom: 60px;
}
.portfolio-nav {
  float: right;
  margin-top: 20px;
  margin-bottom: 10px;
}
.portfolio-nav .portfolio-close-button a {
  display: block;
  height: 30px;
  width: 30px;
  background-color: #eee;
  text-align: center;
  color: #888;
}

.portfolio-nav .portfolio-close-button a:hover {
  background-color: #34c7a9;
  color: #fff;
}

.portfolio-nav .portfolio-close-button a i {
  line-height: 30px;
  font-size: 18px;
}

.portfolio-title {
  padding-top: 40px;
}

.portfolio-title h1 {
  display: inline-block;
  margin: 0;
}

.portfolio-block {
  padding-top: 30px;
}

.portfolio-page-content img {
  max-width: 100%;
}

.portfolio-page-video,
.portfolio-page-carousel,
.portfolio-page-image {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.project-general-info {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}

.project-general-info li {
  margin : 0 0 10px;
}

.project-general-info .fa {
  color: #34c7a9;
  margin-right: 5px;
}

.project-general-info p {
  margin: 0;
}

.share-buttons {
  margin: 25px 0 0;
}

.share-buttons a {
  display: inline-block;
  margin: 0 7px 0 0;
  padding: 0;
  width: 28px;
  height: 28px;
  color: #888;
  background-color: #eee;
  text-align: center;
}

.share-buttons a:hover {
  color: #fff;
  background-color: #34c7a9;
}

.share-buttons a:last-child {
  margin-right: 0;
}

.share-buttons a i {
  font-size: 14px;
  line-height: 28px;
}

/* Tags Block */
.tags-block {
  margin: 25px 0 0;
}

.tags {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tags li {
  display: inline-block;
  margin: 2px 0;
}

.tags li a {
  font-size: 12px;
  color: #666;
  padding: 2px 7px;
  background-color: #eee;
}
/* /Tags Block */

/* =============================================================================
10. Blog post page
============================================================================= */
.blog-post-page {
  height: auto;
}

.blog-post-page  .page-wrapper {
  position: relative;
}

.blog-back-button {
  display: block;
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #fff;
  text-align: center;
  color: #888;
}

.blog-back-button:after {
    display: block;
    width: 5px;
    height: 100%;
    position: absolute;
    bottom: 5px;
    left: -5px;
    content: "";
    transform: skew(0deg,65deg);
    -ms-transform: skew(0deg,65deg);
    -webkit-transform: skew(0deg,65deg);
    -o-transform: skew(0deg,65deg);
    -moz-transform: skew(0deg,65deg);
    background: #d9d9d9;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.blog-back-button:hover,
.blog-back-button:visited:hover {
  color: #fff;
  background-color: #34c7a9;
}

.blog-back-button:hover:after,
.blog-back-button:visited:hover:after {
  background: #2dad94;
}

.blog-back-button i {
  line-height: 40px;
  font-size: 21px;
}

.blog-post-content {
  position: relative;
  background-color: #fff;
  padding: 30px;
  margin: -70px 40px 0;
}

blockquote {
  margin: 20px 0;
}

.blog-post-content .block-title {
  margin: 40px 0 10px;
}

.blog-post-content .post-info {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 5px 10px;
  margin: 30px 0 0;
}

.blog-post-content .post-info .share-buttons {
  display: inline-block;
  margin: 0;
}

.blog-post-content .post-info span {
  display: inline-block;
  padding: 5px 0;
  line-height: 1em;
}

.blog-post-content .post-comment-add {
  max-width: 700px;
}

.blog-post-content .post-comments .media {
  margin-top: 30px;
}

.blog-post-content .post-comments > .media {
  margin-top: 0;
}

.post-comments .media-object {
  max-width: 60px;
  border-radius: 50px;
  border: 2px solid #ddd;
}

.post-comments .media-footer,
.post-comments .media-footer a {
  color: #888;
  font-size: 12px;
  line-height: 1em;
}

.post-comments .media-footer a:hover {
  color: #34c7a9;
}

.blog-post-content .divider {
  color: #d3d3d3;
  margin: 0 2px;
  min-width: 5px;
}

.post-comments .light-gray {
  color: #888;
  font-size: 12px;
}


/* =============================================================================
11. Media Queries
============================================================================= */

@media only screen and (max-width: 1200px) {
  .portfolio-grid figure {
    width: 33.33333%;
  }
  .blog-masonry .item {
    width: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .mobile-visible {
    display: block;
    visibility: visible;
  }

  .header {
    position: fixed;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f5f5f5;
    background-color: #fff;
    z-index: 100;
  }

  .site-title-block {
    margin: 0 20px;
    text-align: left;
  }

  .site-title-block .site-title {
    display: inline-block;
    float: left;
    font-size: 18px;
    margin: 0;
    line-height: 50px;
  }

  .site-title-block .site-description {
    display: none;
  }

  .subpage-block {
    margin-bottom: 40px;
  }

  .my-photo {
    display: none;
  }

  .my-photo img {
    display: none;
  }

  .my-photo-small {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 0 auto 40px;
  }

  .my-photo-small img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  .site-main {
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .site-main-menu {
    text-align: center;
  }

  .section-title-block {
    margin-left: 0;
    margin-right: 0;
  }

  .section-title {
    display: inline-block;
    position: relative;
    margin-left: -20px;
    padding-left: 20px;
  }

  .section-title:after {
    display: none;
  }

  .section-title-block .section-description {
    display: none;
  }

  .site-title {
    color: #222;
    text-shadow: none;
  }

  p.site-description {
    color: #888;
    text-shadow: none;
  }

  .pt-wrapper {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

  }

  .pt-page {
    padding: 15px 20px 60px;
  }

  .site-nav {
    display: block;
    width: 100%;
    max-height: calc(100% - 50px);
    position: fixed;
    padding: 5px 20px;
    top: auto;
    left: 0;
    z-index: 99;
    background-color: #fff;
    overflow: auto;
    -webkit-box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.1);
    box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.1);
    -webkit-transition: all 0.66s ease-in-out;
    -moz-transition: all 0.66s ease-in-out;
    -o-transition: all 0.66s ease-in-out;
    -ms-transition: all 0.66s ease-in-out;
    transition: all 0.66s ease-in-out;
  }

  .site-nav .site-main-menu {
    display: block;
  }

  ul.social-links {
    display: block;
    text-align: center;
    float: none;
    margin: 15px 0;
  }

  ul.social-links li {
    float: none;
    display: inline-block;
  }

  .mobile-menu-hide {
    margin-top: -100%;
  }

  .site-main-menu li {
    display: block;
    padding: 10px 0;
  }

  .blog-post-content {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 769px) {
  .portfolio-grid figure {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .blog-masonry .item {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .portfolio-grid figure {
    width: 100%;
  }
}

/* =============================================================================
12. Updates
============================================================================= */
/* v2.1 - 9 may 2017 */
.g-recaptcha {
  margin-bottom: 20px;
}
